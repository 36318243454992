import { AuthToken, ClientRegistration } from "..";
import { getLog, getLogger } from "../Logger";
import { getAuthSettings, isMessagingEnabled } from "../Settings";
import { UUID } from "../UUID";
import { WindowTitle } from "../WindowTitle";
import { MessageSender } from "./MessageSender";
import { MessageSubscriber } from "./MessageSubscriber";

const log = getLogger("core.messaging.BroadcastMessageHandler");
let broadcastSubscriber: MessageSubscriber;

export enum ClientInfoReplyType {
    EXISTING_PROCESS = "EXISTING_PROCESS",
    PROCESS_ADDED = "PROCESS_ADDED",
    PROCESS_REMOVED = "PROCESS_REMOVED"
}

interface ProcessInfo {
    executionLog?: string;
    programDisplayName: string;
}

export interface Client {
    vmIdentifier: string;
    hostName: string;
    userName: string;
    company: string;
    processInfo: ProcessInfo;
    sessionId: string;
    type: string;
    tokenExpSeconds: number;
    tokenFinalExpSeconds: number;
}

interface ClientInfoRequest {
    originator: Client;
    replyToQueue: string;
    isDetail: boolean;
    targetClients: Client[]
}

export interface ClientInfoReply {
    replyType: string;
    userName: string;
    currentScreen: string;
    client: Client;
}

export class BroadcastMessageHandler {
    public static async init() {
        if (broadcastSubscriber == null && isMessagingEnabled()) {
            broadcastSubscriber = new MessageSubscriber("broadcastMessage", false, message => BroadcastMessageHandler.handleBroadcastMessage(message as ClientInfoRequest));
        }
    }

    public static getInstanceIdentifier() {
        let result = sessionStorage.getItem("instanceIdentifier");
        if (result == null) {
            result = UUID.randomUUID();
            sessionStorage.setItem("instanceIdentifier", result);
            log.debug("Set instance identifer value: %o", result);
        }
        return result;
    }

    public static _clearInstanceIdentifier() {
        log.debug("Clearing instance identifer value, was %o", sessionStorage.getItem("instanceIdentifier"));
        sessionStorage.removeItem("instanceIdentifier");
    }

    private static handleBroadcastMessage(message: ClientInfoRequest) {
        log.debug(() => ["Received broadcast message", message, "from", message?.originator]);
        if (!BroadcastMessageHandler.isMessageForMe(message)) {
            log.debug("Message was not intended for this instance.");
            return;
        }
        if (message.replyToQueue != null) {
            const response = this.getBasicClientInfoReply(ClientInfoReplyType.EXISTING_PROCESS);
            if (message.isDetail === true) {
                // TODO: populate properties and diagnostic tabs of client admin
                response.client.processInfo.executionLog = getLog();
            }
            MessageSender.getInstance().send(message.replyToQueue, false, response, "com.tms.common.lib.clientadmin.ClientInfoReply");
        }
    }

    private static getBasicClientInfoReply(replyType: ClientInfoReplyType): ClientInfoReply {
        const token = AuthToken.get();
        let exp: number = null;
        let finalExp: number = null;
        if (token != null) {
            const parsedToken = AuthToken.parseToken(token);
            exp = parsedToken.exp;
            finalExp = parsedToken.final_exp;
        }
        return {
            replyType: replyType,
            userName: getAuthSettings()?.user_settings?.user_name || null,
            currentScreen: WindowTitle.get(),
            client: {
                hostName: "Web",
                vmIdentifier: this.getInstanceIdentifier(),
                userName: getAuthSettings()?.user_settings?.user_id || null,
                company: "TMS",
                processInfo: {
                    programDisplayName: "PowerBroker Web"
                },
                sessionId: ClientRegistration._getSessionId(),
                type: "WEB_CLIENT",
                tokenExpSeconds: exp,
                tokenFinalExpSeconds: finalExp
            }
        }
    }


    private static isMessageForMe(message: ClientInfoRequest): boolean {
        if (message?.targetClients == null)
            return true;
        for (const client of message.targetClients)
            if (client.vmIdentifier === this.getInstanceIdentifier())
                return true;
        return false;
    }

}
